import { AppLayout } from './components/common/AppLayout';
import { Coupon } from './components/coupon/Coupon';
import { Inquiry } from './components/inquiry/Inquiry';
import { InquiryDetail } from './components/inquiry/InquiryDetail';
import { Login } from './components/login/Login';
import { Notice } from './components/notice/Notice';
import { Product } from './components/product/Product';
import { CenterList } from './components/userList/CenterList';
import { TalkyList } from './components/userList/TalkyList';
import { TherapistList } from './components/userList/TherapistList';
import { Welcome } from './components/welcome/Welcome';

export const LoginPage = () => {
    return <Login />;
};

export const MainPage = () => {
    return (
        <AppLayout title={'홈'}>
            <Welcome />
        </AppLayout>
    );
};
export const TalkyListPage = () => {
    return (
        <AppLayout title={'토키 목록 보기'}>
            <TalkyList />
        </AppLayout>
    );
};

export const TherapistListPage = () => {
    return (
        <AppLayout title={'치료사 목록 보기'}>
            <TherapistList />
        </AppLayout>
    );
};

export const CenterListPage = () => {
    return (
        <AppLayout title={'센터 목록 보기'}>
            <CenterList />
        </AppLayout>
    );
};

export const NoticePage = () => {
    return (
        <AppLayout title={'공지사항 등록'}>
            <Notice />
        </AppLayout>
    );
};

export const InquiryPage = () => {
    return (
        <AppLayout title={'1대1 문의 목록'}>
            <Inquiry />
        </AppLayout>
    );
};

export const InquiryDetailPage = () => {
    return (
        <AppLayout title={'1대1 문의 확인'}>
            <InquiryDetail />
        </AppLayout>
    );
};

export const CouponPage = () => {
    return (
        <AppLayout title={'쿠폰 관리'}>
            <Coupon />
        </AppLayout>
    );
};

export const ProductPage = () => {
    return (
        <AppLayout title={'쿠폰 관리'}>
            <Product />
        </AppLayout>
    );
};
