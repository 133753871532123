import React, { useState, useMemo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Chip, CircularProgress, Link } from '@mui/material';
import { getHighlightedText } from '../../utils/util';
import CommonTable from '../common/CommonTable';
import { CODE_DICT } from '../../utils/const';
import { useCenterList } from '../../hooks/center';
import CenterInfoModal from './CenterInfoModal';
import CenterSearchHeader from './CenterSearchHeader';

// 치료사 목록 페이지
export const CenterList = () => {
    // const [items, setItems] = useState([]);
    // const [totalCount, setTotalCount] = useState(1);

    // 치료사 정보 수정 모달
    const [selected, setSelected] = useState(null); // 선택된 치료사 id
    // const [selectedTherapist, setSelectedTherapist] = useState();
    const [open, setOpen] = useState(false); //  모달 open/close
    const [editMode, setEditMode] = useState(false);
    const handleOpenInsertModal = useCallback(() => {
        setOpen(true);
        setEditMode(false);
    }, []);
    // 모달 열기
    const handleOpenInfoModal = useCallback(
        record => () => {
            setSelected(record.centerId);
            // setSelectedTherapist(record);
            setOpen(true);
            setEditMode(true);
        },
        [],
    );
    // 모달 닫기
    const handleClose = () => {
        setOpen(false);
    };

    // 검색 필터
    const [filterData, setFilterData] = useState({ centerStatus: 'ALL', status: 'ALL', untactLessonStatus: 'ALL', keyword: '' });

    // 검색 필터 react-hook-form
    const searchForm = useForm({
        defaultValues: {
            centerStatus: 'ALL', // 센터 소속
            status: 'ALL', // 치료사 계정 상태
            untactLessonStatus: 'ALL', // 비대면 수업 승인 상태
            keyword: '', // 검색어
        },
    });

    // 치료사 목록 react-query
    const { data, isLoading } = useCenterList();

    // 필터된 테이블 데이터
    const tableData = useMemo(() => {
        // 텍스트 검색 필터
        const searchFieldList = ['name', 'address', 'contact', 'chiefName'];
        const searchfilter = v => searchFieldList.some(fieldName => v[fieldName] && v[fieldName].includes(filterData.keyword));

        // status select 필터
        const statusFieldList = ['status'];
        const statusFilter = v =>
            statusFieldList.every(fieldName => filterData[fieldName] === 'ALL' || v[fieldName] === Number(filterData[fieldName]));

        // 필터된 데이터
        const filteredData = data?.data?.filter(v => statusFilter(v) && searchfilter(v)) || [];
        return filteredData;
    }, [data?.data, filterData]);

    // 테이블 컬럼
    const columns = useMemo(
        () => [
            {
                id: 'name',
                disablePadding: false,
                label: '이름',
                width: '20%',
                render: (text, record) => (
                    <Link underline='hover' sx={{ cursor: 'pointer' }} onClick={handleOpenInfoModal(record)}>
                        {getHighlightedText(text, filterData.keyword)}
                    </Link>
                ),
            },
            {
                id: 'status',
                disablePadding: false,
                label: '센터 소속',
                width: '15%',
                render: (text, record) => {
                    const item = CODE_DICT.centerStatus.find(v => v.code === text);
                    return item && item.codeDesc;
                },
            },
            {
                id: 'chiefName',
                disablePadding: false,
                label: '센터장',
                width: '10%',
                render: (text, record) => getHighlightedText(text, filterData.keyword),
            },
            {
                id: 'address',
                disablePadding: false,
                label: '주소',
                width: '40%',
                render: (text, record) => getHighlightedText(text, filterData.keyword),
            },
            {
                id: 'contact',
                disablePadding: false,
                label: '연락처',
                width: '15%',
                render: (text, record) => getHighlightedText(text, filterData.keyword),
            },
        ],
        [handleOpenInfoModal, filterData.keyword],
    );

    return (
        <>
            <h3>센터 : {tableData?.length}</h3>
            <FormProvider {...searchForm}>
                <CenterSearchHeader setFilterData={setFilterData} handleOpenInsertModal={handleOpenInsertModal} />
            </FormProvider>
            <Box textAlign='center'>
                {isLoading ? <CircularProgress /> : <CommonTable columns={columns} rows={tableData} rowKey='centerId' />}
            </Box>
            <CenterInfoModal open={open} handleClose={handleClose} centerId={selected} editMode={editMode} />
        </>
    );
};
