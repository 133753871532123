import React, { useEffect, useState } from 'react';
import { CouponGenerator } from './CouponGenerator';
import { CouponRegister } from './CouponRegister';
import { CouponList } from './CouponList';
import { CouponDiver } from './Coupon.style';

export const Coupon = () => {
    return (
        <>
            <h3 style={{ marginBottom: '50px' }}>쿠폰 관리</h3>
            <CouponDiver>
                <h4>쿠폰 정보 등록</h4>
                <CouponRegister />
            </CouponDiver>
            <CouponDiver>
                <h4>쿠폰 생성</h4>
                <CouponGenerator />
            </CouponDiver>
            <CouponDiver>
                <h4>쿠폰 목록</h4>
                <CouponList />
            </CouponDiver>
        </>
    );
};
