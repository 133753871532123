import * as React from 'react';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CampaignIcon from '@mui/icons-material/Campaign';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { DomainOutlined } from '@mui/icons-material';

export const firstListItems = (
    <div>
        <Link href={'/main'} style={{ textDecoration: 'none' }}>
            <ListItem button>
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary='홈' />
            </ListItem>
        </Link>
        <Divider />
        <Link href={'/notice'} style={{ textDecoration: 'none' }}>
            <ListItem button>
                <ListItemIcon>
                    <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary='공지사항 등록' />
            </ListItem>
        </Link>
        <Link href={'/inquiry'} style={{ textDecoration: 'none' }}>
            <ListItem button>
                <ListItemIcon>
                    <ContactMailIcon />
                </ListItemIcon>
                <ListItemText primary='1대1 문의 확인' />
            </ListItem>
        </Link>
        <ListItem button>
            <ListItemIcon>
                <EmojiPeopleIcon />
            </ListItemIcon>
            <ListItemText primary='자주하는 질문 등록' style={{ color: 'gray' }} />
        </ListItem>
        <Link href={'/product'} style={{ textDecoration: 'none' }}>
            <ListItem button>
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary='상품 관리' />
            </ListItem>
        </Link>
        <Link href={'/coupon'} style={{ textDecoration: 'none' }}>
            <ListItem button>
                <ListItemIcon>
                    <ShoppingCartCheckoutIcon />
                </ListItemIcon>
                <ListItemText primary='쿠폰 관리' />
            </ListItem>
        </Link>
    </div>
);

export const secondaryListItems = (
    <div>
        <Link href={'/talky/list'} style={{ textDecoration: 'none' }}>
            <ListItem button>
                <ListItemIcon>
                    <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary='토키 목록' />
            </ListItem>
        </Link>

        <Link href={'/therapist/list'} style={{ textDecoration: 'none' }}>
            <ListItem button>
                <ListItemIcon>
                    <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText primary='치료사 목록' />
            </ListItem>
        </Link>

        <Link href={'/center/list'} style={{ textDecoration: 'none' }}>
            <ListItem button>
                <ListItemIcon>
                    <DomainOutlined />
                </ListItemIcon>
                <ListItemText primary='센터 목록' />
            </ListItem>
        </Link>
    </div>
);

export const thirdListItems = (
    <div>
        <ListItem button>
            <ListItemIcon>
                <DirectionsWalkIcon />
            </ListItemIcon>
            <ListItemText primary='매칭 신청한 토키 목록' style={{ color: 'gray' }} />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary='토키-치료사 매칭 목록' style={{ color: 'gray' }} />
        </ListItem>
        <ListItem button>
            <ListItemIcon>
                <ContentPasteIcon />
            </ListItemIcon>
            <ListItemText primary='치료사 수업 내역' style={{ color: 'gray' }} />
        </ListItem>
    </div>
);

export const lastListItems = (
    <div>
        <ListItem button>
            <ListItemIcon>
                <CancelIcon />
            </ListItemIcon>
            <ListItemText primary='회원탈퇴 목록' style={{ color: 'gray' }} />
        </ListItem>
    </div>
);
