import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useConfirm } from '../../context/confirm';

// 공통 컨펌 모달
export default function CommonConfirm() {
    const { open, setOpen, modalData } = useConfirm();

    const handleOk = () => {
        modalData?.onOk(); // callback 실행
        setOpen(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{modalData?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{modalData?.contentText}</DialogContentText>
                {modalData?.content}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{modalData?.cancelText || '취소'}</Button>
                <Button onClick={handleOk} autoFocus>
                    {modalData?.okText || '확인'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
