import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { callCenterInfoAPI, callCenterListAPI, callCenterRegisterAPI, callCenterUpdateAPI } from '../apis/api';

// 센터 목록 조회
export const useCenterList = () => useQuery(['centerList'], callCenterListAPI);

// 치료사 정보 GET
export const useCenterDetail = (centerId, reset) =>
    useQuery(['center', centerId], () => callCenterInfoAPI(centerId), {
        refetchOnWindowFocus: false, // 포커스 될 때마다 fetch 방지
        enabled: centerId > 0,
    });

// 센터 정보 등록 mutation
export const useCenterRegisterMutation = showMessage => {
    const queryClient = useQueryClient();

    return useMutation(['centerInsert'], callCenterRegisterAPI, {
        onError: error => {
            showMessage(error?.response?.data?.message || '에러 발생', 'error');
        },
        onSuccess: data => {
            queryClient.refetchQueries(['centerList']);
            showMessage(data.message, 'success');
        },
    });
};

// 센터 정보 수정 mutation
export const useCenterUpdateMutation = (centerId, showMessage) => {
    const queryClient = useQueryClient();

    return useMutation(['centerUpdate', centerId], callCenterUpdateAPI, {
        onError: error => {
            showMessage(error?.response?.data?.message || '에러 발생', 'error');
        },
        onSuccess: data => {
            queryClient.refetchQueries(['centerList']);
            showMessage(data.message, 'success');
        },
    });
};
