import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { TitleArea, ContentArea, ApplyButtonWrapper } from './Notice.style';
import { callAdminNoticeAPI } from '../../apis/api';

export const Notice = () => {
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);

    const handleNoticeAPI = () => {
        callAdminNoticeAPI(title, content)
            .then(res => {
                if (res.data.result === 'success') {
                    alert('등록되었습니다.');
                }
            })
            .catch(e => {});
    };

    return (
        <>
            <h3>공지사항 등록</h3>
            <ApplyButtonWrapper>
                <Button variant='contained' size='medium' onClick={() => handleNoticeAPI()}>
                    등록하기
                </Button>
            </ApplyButtonWrapper>
            <TitleArea placeholder={'제목'} onChange={e => setTitle(e.target.value)} />
            <ContentArea placeholder={'내용'} onChange={e => setContent(e.target.value)} />
        </>
    );
};
