import React, { useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import {
    CenterListPage,
    CouponPage,
    LoginPage,
    InquiryDetailPage,
    InquiryPage,
    MainPage,
    NoticePage,
    ProductPage,
    TalkyListPage,
    TherapistListPage,
} from '../pages';
import { useAuth } from '../context/auth';

// rest: {path: /main/undefined}
const PrivateRoute = () => {
    const { user } = useAuth();
    const location = useLocation();

    // 로그인 페이지로
    if (!user) {
        return <Navigate replace to='/' state={{ from: location.pathname }} />;
    }

    return <Outlet />;
};

const IndexRoutes = () => {
    return (
        <Routes>
            <Route path='*' element={<>404 Not Found</>} />
            <Route path='/' element={<LoginPage />} />
            <Route element={<PrivateRoute />}>
                <Route path='main' element={<MainPage />} />
                <Route path='talky/list' element={<TalkyListPage />} />
                <Route path='therapist/list' element={<TherapistListPage />} />
                <Route path='center/list' element={<CenterListPage />} />
                <Route path='notice' element={<NoticePage />} />
                <Route path='inquiry' element={<InquiryPage />} />
                <Route path='inquiry/:idx' element={<InquiryDetailPage />} />
                <Route path='coupon' element={<CouponPage />} />
                <Route path='product' element={<ProductPage />} />
            </Route>
        </Routes>
    );
};

export default IndexRoutes;
