export let accessToken = '';

export const setAccessToken = s => {
    accessToken = s;
};

export const getAccessToken = () => {
    // TODO: 아래줄 나중에 새로고침 기능 되면 지우기, 왜냐면 localStorage에서 액세스 토큰 안 가져올거니까:
    const accessToken = JSON.parse(localStorage.getItem('adminUser'))?.accessToken;
    return accessToken;
};

export const authHeader = () => {
    const accessToken = getAccessToken();

    if (accessToken) {
        return { Authorization: `Bearer ${accessToken}` };
    } else return {};
};
