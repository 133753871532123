import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { CheckRounded, ClearRounded, Photo, Visibility } from '@mui/icons-material';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useConfirm } from '../../context/confirm';
import { useSnackbar } from '../../context/snackbar';
import { CODE_DICT } from '../../utils/const';
import TherapistDenyModal from './TherapistDenyModal';
import { useCenterDetail, useCenterList, useCenterRegisterMutation, useCenterUpdateMutation } from '../../hooks/center';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    px: 4,
    py: 2,
};

const startTimeList = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'];
const endTimeList = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'];

const getMenuItemList = codeList =>
    codeList.map(v => (
        <MenuItem key={v.code} value={v.code}>
            {v.codeDesc}
        </MenuItem>
    ));

// mui Select + react-hook-form
const FormSelect = ({ name, label, optionList, width, fullWidth, ...rest }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ my: 1, width }} fullWidth={fullWidth}>
                    <InputLabel id={`${name}Label`} size='small'>
                        {label}
                    </InputLabel>
                    <Select
                        labelId={`${name}Label`}
                        id={name}
                        label={label}
                        size='small'
                        onChange={onChange}
                        value={value}
                        fullWidth={fullWidth}
                        {...rest}
                    >
                        {getMenuItemList(optionList)}
                    </Select>
                </FormControl>
            )}
        />
    );
};

// mui Text Field + react-hook-form
const FormTextField = ({ name, label, width, readOnly, fullWidth, ...rest }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ my: 1, width }} fullWidth={fullWidth}>
                    <TextField
                        onChange={onChange}
                        value={value || ''}
                        label={label}
                        size='small'
                        inputProps={{ readOnly }}
                        fullWidth={fullWidth}
                        {...rest}
                    />
                </FormControl>
            )}
        />
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 258,
        },
    },
};

// mui multi-select + react-hook-form
export function FormMultiSelect({ name, label, width, fullWidth, optionList, ...rest }) {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ my: 1, width }} fullWidth={fullWidth}>
                    <InputLabel id={`${name}Label`} size='small'>
                        {label}
                    </InputLabel>
                    <Select
                        labelId={`${name}Label`}
                        id={name}
                        label={label}
                        multiple
                        value={value || []}
                        onChange={onChange}
                        MenuProps={MenuProps}
                        size='small'
                        fullWidth={fullWidth}
                        {...rest}
                    >
                        {getMenuItemList(optionList)}
                    </Select>
                </FormControl>
            )}
        />
    );
}

// 센터 정보 영역 구분선
const Title = ({ children }) => (
    <Divider textAlign='left' sx={{ fontWeight: 'bold', marginTop: '20px' }}>
        {children}
    </Divider>
);

const getFileName = path =>
    path
        ?.replace(/^.*[\\/]/, '') // 파일 이름만 남김
        ?.replace(/\?[^?]*$/, ''); // querystring 삭제

// 센터 정보 조회/수정 모달
const CenterInfoModal = ({ open, handleClose, centerId, editMode }) => {
    /* react-hook-form */
    const infoForm = useForm({
        defaultValues: {
            name: '', // 센터 이름
            status: '2', // 기본 = 협회
            address: '', // 주소
            contact: '', // 연락처
        },
    });
    const { control, reset, register, resetField } = infoForm;

    const { showConfirm } = useConfirm(); // 컨펌 모달
    const { showMessage } = useSnackbar(); // 메시지

    // 센터 정보 GET
    const queryClient = useQueryClient();
    const { data, isFetching } = useCenterDetail(centerId);

    const insertMutation = useCenterRegisterMutation(showMessage);
    // 센터 정보 수정 mutation
    const updateMutation = useCenterUpdateMutation(centerId, showMessage);

    // 모달 닫기
    const handleOnClose = useCallback(() => {
        // 모달 닫기
        handleClose();
    }, [handleClose]);

    // 센터 정보 등록
    const handleRegister = useCallback(
        formData => {
            // 확인 모달
            showConfirm({
                title: '정보 등록',
                contentText: '센터 정보를 등록하시겠습니까?',
                onOk: () => {
                    insertMutation.mutate(formData);
                    handleClose();
                },
            });
        },
        [showConfirm, insertMutation, handleClose],
    );

    // 센터 정보 수정
    const handleUpdate = useCallback(
        formData => {
            const { name, status, address, contact } = formData;
            const submitData = { centerId, name, status, address, contact };

            // 확인 모달
            showConfirm({
                title: '정보 수정',
                contentText: '센터 정보를 수정하시겠습니까?',
                onOk: () => {
                    updateMutation.mutate(submitData);
                    handleClose();
                },
            });
        },
        [showConfirm, centerId, updateMutation, handleClose],
    );

    // 센터 refetch 시 reset
    useEffect(() => {
        if (data && reset) {
            reset(
                { ...data?.data }, // 폼 리셋
                { keepDefaultValues: true },
            );
        }
    }, [data, reset]);

    // 모달 열 때마다 refetch
    useEffect(() => {
        if (open) {
            if (editMode && centerId) {
                queryClient.refetchQueries(['center', centerId]);
            }
        } else {
            reset && reset();
        }
    }, [open, centerId, queryClient, reset, editMode]);

    return (
        <>
            <Modal open={open} onClose={handleOnClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <div>
                    <Box sx={style}>
                        <Typography id='modal-modal-title' variant='h6' component='h2'>
                            센터 정보
                        </Typography>
                        <FormProvider {...infoForm}>
                            <form onSubmit={infoForm.handleSubmit(editMode ? handleUpdate : handleRegister)}>
                                <Box id='modal-modal-description'>
                                    <Box sx={{ mt: 2, height: 388, overflowY: 'auto' }}>
                                        {isFetching ? (
                                            <CircularProgress />
                                        ) : (
                                            <>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <FormTextField name='name' label='이름' fullWidth required />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormSelect
                                                            name='status'
                                                            label='소속'
                                                            fullWidth
                                                            optionList={CODE_DICT.centerStatus}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormTextField name='address' label='주소' fullWidth required />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormTextField name='contact' label='연락처' fullWidth required />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </Box>

                                    <Box sx={{ mt: 2, textAlign: 'right' }}>
                                        <Button variant='outlined' sx={{ mr: 1 }} onClick={handleOnClose}>
                                            닫기
                                        </Button>
                                        <Button variant='contained' sx={{ mr: 1 }} type='submit'>
                                            {editMode ? '수정' : '등록'}
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </FormProvider>
                    </Box>
                </div>
            </Modal>
        </>
    );
};

export default CenterInfoModal;
