import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ListWrapper } from './Inquiry.style';
import { callAdminInquiryAPI } from '../../apis/api';

export const Inquiry = () => {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const [keyword, setKeyword] = useState(null);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '65%',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        callAdminInquiryAPI(currentPage, keyword)
            .then(res => {
                const { total_count, total_page, items } = res.data.data;
                setCurrentPage(currentPage);
                setTotalPage(total_page);
                setTotalCount(total_count);
                setItems(items);
            })
            .catch(e => {});
    }, [currentPage]);

    const handlePagination = (e, v) => {
        e.persist();
        setCurrentPage(v);
    };

    return (
        <>
            <h3>1대1 문의 목록</h3>
            <ListWrapper>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>문의제목</TableCell>
                                <TableCell align={'center'}>문의날짜</TableCell>
                                <TableCell align={'center'}>답변여부</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item, idx) => (
                                <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <Link to={`/inquiry/${item.idx}`} state={{ item }}>
                                        <TableCell style={{ width: '1920px' }}>{`${item.content.substr(0, 20)}...`}</TableCell>
                                    </Link>
                                    <TableCell style={{ width: '10%' }} align={'center'}>
                                        {item.recdate}
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }} align={'center'}>
                                        {item.extra1}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ListWrapper>

            <Pagination
                style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}
                count={totalPage}
                page={currentPage}
                onChange={handlePagination}
                siblingCount={1}
            />
        </>
    );
};
