import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
    Box,
    Checkbox,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Delete, FilterList } from '@mui/icons-material';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const DEFAULT_ORDER = 'asc'; // 기본 오름/내림차순
const DEFAULT_ORDER_BY = null; // 기본 정렬 기준
const DEFAULT_ROWS_PER_PAGE = 10; // 기본 페이지 당 row 수

// 공통 테이블 헤드(컬럼 제목) 컴포넌트
function CommonTableHead({ columns, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort }) {
    const createSortHandler = newOrderBy => event => {
        onRequestSort(event, newOrderBy);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding='checkbox'>
                    <Checkbox
                        color='primary'
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {columns.map(column => (
                    <TableCell
                        key={column.id}
                        align={column.align || 'left'}
                        padding={column.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                            sx={{ fontWeight: 'bold' }}
                        >
                            {column.label}
                            {orderBy === column.id && (
                                <Box component='span' sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            )}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

CommonTableHead.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string,
    rowCount: PropTypes.number.isRequired,
};

// 공통 테이블 헤드 툴바 컴포넌트
function CommonTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
                    치료사 {numSelected}명 선택
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant='h6' id='tableTitle' component='div'>
                    치료사 목록
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title='Delete'>
                    <IconButton>
                        <Delete />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title='Filter list'>
                    <IconButton>
                        <FilterList />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}

CommonTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

// 공통 테이블 컴포넌트
export default function CommonTable({ columns, rows, rowKey }) {
    const [order, setOrder] = useState(DEFAULT_ORDER); // 오름차순/내림차순
    const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY); // 정렬 기준
    const [selected, setSelected] = useState([]); // 체크된 row 목록
    const [page, setPage] = useState(0); // 현재 페이지
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE); // 페이지 당 row 수
    const [paddingHeight, setPaddingHeight] = useState(0);

    // 현재 페이지 내 row
    const visibleRows = useMemo(() => {
        const sortedRows = stableSort(rows, getComparator(order, orderBy)); // row 정렬 결과
        const updatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage); // 현재 페이지 내 row들 결과
        return updatedRows;
    }, [order, orderBy, page, rows, rowsPerPage]);

    // 컬럼 기준으로 정렬
    const handleRequestSort = useCallback(
        (event, clickedOrderBy) => {
            const newOrderBy = orderBy === clickedOrderBy && order === 'desc' ? null : clickedOrderBy; // 내림차순 된 컬럼 클릭 시 정렬 초기화
            const isAsc = orderBy === newOrderBy && order === 'asc';
            const toggledOrder = isAsc ? 'desc' : 'asc';

            setOrder(toggledOrder);
            setOrderBy(newOrderBy);
        },
        [order, orderBy],
    );

    // 전체 체크박스 클릭
    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    // 체크박스 클릭
    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    // 페이지 변경
    const handleChangePage = useCallback(
        (event, newPage) => {
            setPage(newPage);

            // Avoid a layout jump when reaching the last page with empty rows.
            const numEmptyRows = newPage > 0 ? Math.max(0, (1 + newPage) * rowsPerPage - rows.length) : 0;

            const newPaddingHeight = 53 * numEmptyRows;
            setPaddingHeight(newPaddingHeight);
        },
        [rows, rowsPerPage],
    );

    // 페이지 당 row 갯수 변경
    const handleChangeRowsPerPage = useCallback(event => {
        const updatedRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(updatedRowsPerPage);

        setPage(0);

        // There is no layout jump to handle on the first page.
        setPaddingHeight(0);
    }, []);

    // 선택되어 있는지 확인
    const isSelected = id => selected.indexOf(id) !== -1;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                {/* <CommonTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'medium'}>
                        <CommonTableHead
                            columns={columns}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows
                                ? visibleRows.map((row, index) => {
                                      const rowId = rowKey ? row[rowKey] : row.id;
                                      const isItemSelected = isSelected(rowId);
                                      const labelId = `enhanced-table-checkbox-${index}`;

                                      return (
                                          <TableRow
                                              role='checkbox'
                                              aria-checked={isItemSelected}
                                              tabIndex={-1}
                                              key={rowId}
                                              selected={isItemSelected}
                                              //   sx={{ cursor: 'pointer' }}
                                          >
                                              <TableCell padding='checkbox'>
                                                  <Checkbox
                                                      color='primary'
                                                      onClick={event => handleClick(event, rowId)}
                                                      checked={isItemSelected}
                                                      inputProps={{
                                                          'aria-labelledby': labelId,
                                                      }}
                                                  />
                                              </TableCell>
                                              {columns.map(column => (
                                                  <TableCell key={column.id} align={column.align || 'left'} width={column.width || 'auto'}>
                                                      {column.render ? column.render(row?.[column.id], row) : row?.[column.id]}
                                                  </TableCell>
                                              ))}
                                          </TableRow>
                                      );
                                  })
                                : null}
                            {paddingHeight > 0 && (
                                <TableRow
                                    style={{
                                        height: paddingHeight,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 30, 50]}
                    component='div'
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
