import styled from '@emotion/styled';
import breakpoints from '../../utils/breakpoints';

export const ListWrapper = styled.div`
    display: inline-grid;
    justify-items: center;
    grid-template-columns: 1fr;

    @media (min-width: ${breakpoints.device.tablet}) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: ${breakpoints.device.desktop}) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
`;

export const CardWrapper = styled.div`
    width: 250px;

    @media (min-width: ${breakpoints.device.tablet}) {
        width: 200px;
    }

    @media (min-width: ${breakpoints.device.desktop}) {
        width: 350px;
    }
`;
export const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    height: 40vh;
    overflow-y: auto;
`;

export const Divider = styled.div`
    display: flex;
    flex-flow: wrap;
    margin-bottom: 20px;
    // justify-content: space-between;
`;

export const Title = styled.div`
    width: 100%;
    height: 50px;
    margin-left: 15px;
    font-weight: bold;
`;

export const CertificationWrapper = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
`;

export const CertificationIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;
    flex-flow: wrap;

    .certi-icon {
        margin-left: 15px;
        margin-right: 15px;
    }
`;

export const Certi = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin: 0;
    }
`;
