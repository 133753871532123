import styled from '@emotion/styled';
import breakpoints from '../../utils/breakpoints';

export const ApplyButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
`;
export const TitleArea = styled.textarea`
    width: 100%;
    height: 40px;
    resize: none;
    overflow: hidden;
    padding: 7px 10px;
    margin-bottom: 20px;
    cursor: default;
`;

export const ContentArea = styled(TitleArea)`
    height: 450px;
`;

export const EtcArea = styled(TitleArea)`
    width: 180px;
    margin: 0;
`;

export const CategoryWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
`;

export const Category = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;

    h5 {
        margin-right: 20px;
    }
`;
