import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Pagination } from '@mui/material';
import { CardWrapper, ListWrapper } from '../userList/UserList.style';
import { callAdminCouponList } from '../../apis/api';

export const CouponList = () => {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        callAdminCouponList(currentPage)
            .then(res => {
                const { total_count, total_page, items } = res.data.data;
                setCurrentPage(currentPage);
                setTotalPage(total_page);
                setTotalCount(total_count);
                setItems(items);
            })
            .catch(e => {});
    }, [currentPage]);

    const handlePagination = (e, v) => {
        e.persist();
        setCurrentPage(v);
    };

    return (
        <>
            <ListWrapper>
                {items.map((item, idx) => {
                    return (
                        <CardWrapper key={idx}>
                            <Card sx={{ m: 1, Width: 250 }} style={{ marginBottom: '25px', minHeight: '15px' }}>
                                <CardContent>
                                    <Typography variant='h7' component='div' style={{ marginBottom: '25px' }}>
                                        {`${item.publish_type} ${item.name}`}
                                    </Typography>
                                    <Typography variant='body2' style={{ height: '40px', overflow: 'hidden' }}>
                                        {`만료기한:${item.expiration_date}`}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small'>자세히보기</Button>
                                </CardActions>
                            </Card>
                        </CardWrapper>
                    );
                })}
            </ListWrapper>
            <Pagination
                style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}
                count={totalPage}
                page={currentPage}
                onChange={handlePagination}
                siblingCount={1}
            />
        </>
    );
};
