import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    callAdminTherapistApprovalAPI,
    callAdminTherapistInfoAPI,
    callAdminTherapistListAPI,
    callAdminTherapistResetPasswordAPI,
    callAdminTherapistUntactApprovalAPI,
    callAdminTherapistUpdateAPI,
} from '../apis/api';

export const useTherapistList = () => useQuery(['therapistList'], callAdminTherapistListAPI);

// 치료사 정보 GET
export const useTherapistDetail = (therapistId, reset) =>
    useQuery(['therapist', therapistId], () => callAdminTherapistInfoAPI(therapistId), {
        refetchOnWindowFocus: false, // 포커스 될 때마다 fetch 방지
        enabled: therapistId > 0,
        onSuccess: data => {
            reset &&
                reset(
                    { ...data?.data, educatBackgroundArr: data?.data?.educatBackgroundArr.map(v => ({ name: v })) }, // 폼 리셋
                    { keepDefaultValues: true }, // 초기값 유지
                );
        },
    });

// 치료사 비대면 승인 취소 mutation
export const useTherapistApproveMutation = (therapistId, showMessage) => {
    const queryClient = useQueryClient();

    return useMutation(['approve', therapistId], callAdminTherapistApprovalAPI, {
        onSuccess: data => {
            queryClient.refetchQueries(['therapist', therapistId]);
            queryClient.refetchQueries(['therapistList']);
            showMessage(data.message, 'success');
        },
    });
};

// 치료사 비대면 승인 mutation
export const useTherapistApproveUntactMutation = (therapistId, showMessage) => {
    const queryClient = useQueryClient();

    return useMutation(['approveUntact', therapistId], callAdminTherapistUntactApprovalAPI, {
        onSuccess: data => {
            queryClient.refetchQueries(['therapist', therapistId]);
            queryClient.refetchQueries(['therapistList']);
            showMessage(data.message, 'success');
        },
    });
};

// 치료사 정보 수정 mutation
export const useTherapistUpdateMutation = (therapistId, showMessage) => {
    const queryClient = useQueryClient();

    return useMutation(['update', therapistId], callAdminTherapistUpdateAPI, {
        onError: error => {
            showMessage(error?.response?.data?.message || '에러 발생', 'error');
        },
        onSuccess: data => {
            queryClient.refetchQueries(['therapist', therapistId]);
            queryClient.refetchQueries(['therapistList']);
            showMessage(data.message, 'success');
        },
    });
};

// 치료사 비밀번호 리셋 mutation
export const useTherapistResetPasswordMutation = (therapistId, showMessage) => {
    const queryClient = useQueryClient();

    return useMutation(['resetPassword', therapistId], callAdminTherapistResetPasswordAPI, {
        onError: error => {
            showMessage(error?.response?.data?.message || '에러 발생', 'error');
        },
        onSuccess: data => {
            queryClient.refetchQueries(['therapist', therapistId]);
            queryClient.refetchQueries(['therapistList']);
            showMessage(data.message, 'success');
        },
    });
};
