import React, { useState, useMemo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Chip, CircularProgress, Link } from '@mui/material';
import CommonTable from '../common/CommonTable';
import TherapistInfoModal from './TherapistInfoModal';
import TherapistSearchHeader from './TherapistSearchHeader';
import { useTherapistList } from '../../hooks/therapist';
import { getHighlightedText } from '../../utils/util';
import { CODE_DICT } from '../../utils/const';

// 치료사 목록 페이지
export const TherapistList = () => {
    // const [items, setItems] = useState([]);
    // const [totalCount, setTotalCount] = useState(1);

    // 치료사 정보 수정 모달
    const [selected, setSelected] = useState(null); // 선택된 치료사 id
    // const [selectedTherapist, setSelectedTherapist] = useState();
    const [open, setOpen] = useState(false); //  모달 open/close
    // 모달 열기
    const handleOpenInfoModal = useCallback(
        record => () => {
            setSelected(record.id);
            // setSelectedTherapist(record);
            setOpen(true);
        },
        [],
    );
    // 모달 닫기
    const handleClose = () => {
        setOpen(false);
    };

    // 검색 필터
    const [filterData, setFilterData] = useState({ centerStatus: 'ALL', status: 'ALL', untactLessonStatus: 'ALL', keyword: '' });

    // 검색 필터 react-hook-form
    const searchForm = useForm({
        defaultValues: {
            centerStatus: 'ALL', // 센터 소속
            status: 'ALL', // 치료사 계정 상태
            untactLessonStatus: 'ALL', // 비대면 수업 승인 상태
            keyword: '', // 검색어
        },
    });

    // 치료사 목록 react-query
    const { data, isLoading } = useTherapistList();

    // 필터된 테이블 데이터
    const tableData = useMemo(() => {
        // 텍스트 검색 필터
        const searchFieldList = ['fullName', 'userName', 'userEmail'];
        const searchfilter = v => searchFieldList.some(fieldName => v[fieldName].includes(filterData.keyword));

        // status select 필터
        const statusFieldList = ['centerStatus', 'status', 'untactLessonStatus'];
        const statusFilter = v =>
            statusFieldList.every(fieldName => filterData[fieldName] === 'ALL' || v[fieldName] === Number(filterData[fieldName]));

        // 필터된 데이터
        const filteredData = data?.data?.items?.filter(v => statusFilter(v) && searchfilter(v)) || [];
        return filteredData;
    }, [data?.data?.items, filterData]);

    // 테이블 컬럼
    const columns = useMemo(
        () => [
            {
                id: 'centerStatus',
                disablePadding: false,
                label: '센터 소속',
                width: '10%',
                render: (text, record) => {
                    const item = CODE_DICT.centerStatus.find(v => v.code === text);
                    return item && item.codeDesc;
                },
            },
            {
                id: 'affiliation',
                disablePadding: false,
                label: '센터명',
                width: '15%',
            },
            {
                id: 'fullName',
                disablePadding: false,
                label: '이름',
                width: '8%',
                render: (text, record) => (
                    <Link underline='hover' sx={{ cursor: 'pointer' }} onClick={handleOpenInfoModal(record)}>
                        {getHighlightedText(text, filterData.keyword)}
                    </Link>
                ),
            },
            {
                id: 'userName',
                disablePadding: false,
                label: '아이디',
                width: '10%',
                render: (text, record) => getHighlightedText(text, filterData.keyword),
            },
            {
                id: 'userEmail',
                disablePadding: false,
                label: '이메일',
                render: (text, record) => getHighlightedText(text, filterData.keyword),
            },
            {
                id: 'status',
                disablePadding: false,
                label: '가입 승인',
                width: '10%',
                render: (text, record) => {
                    const item = CODE_DICT.status.find(v => v.code === text);
                    return item && <Chip label={item.codeDesc} color={item.color} />;
                },
            },
            {
                id: 'untactLessonStatus',
                disablePadding: false,
                label: '비대면 수업',
                width: '10%',
                render: (text, record) => {
                    const item = CODE_DICT.untactLessonStatus.find(v => v.code === text);
                    return item && <Chip label={item.codeDesc} color={item.color} variant={item.variant} />;
                },
            },
        ],
        [handleOpenInfoModal, filterData.keyword],
    );

    return (
        <>
            <h3>치료사 : {tableData?.length}명</h3>
            <FormProvider {...searchForm}>
                <TherapistSearchHeader setFilterData={setFilterData} />
            </FormProvider>
            <Box textAlign='center'>{isLoading ? <CircularProgress /> : <CommonTable columns={columns} rows={tableData} />}</Box>
            <TherapistInfoModal open={open} handleClose={handleClose} therapistId={selected} />
        </>
    );
};
