import styled from '@emotion/styled';
import breakpoints from '../../utils/breakpoints';

export const CouponDiver = styled.div`
    border-bottom: 0.5px solid gray;
    padding-bottom: 30px;
    margin-bottom: 30px;
`;
export const GeneratorsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
