// image, video, files URL
export const CDN_URL =
    process.env === 'production'
        ? 'https://storage.googleapis.com/cdn.talkytalky-lab.com'
        : 'https://storage.googleapis.com/cdn.talkytalky-lab.com';

export const API_URL = process.env.NODE_ENV === 'production' ? 'https://www.talkytalky.kr/api/v1' : 'http://localhost:5400/api/v1';

export const CODE_DICT = {
    // 성별 코드 목록
    gender: [
        { code: 'male', codeDesc: '남' },
        { code: 'female', codeDesc: '여' },
    ],
    // 국가 코드 목록
    nation: [
        { code: 'KR', codeDesc: '대한민국' },
        { code: 'AB', codeDesc: '해외' },
    ],

    // 학력 정보 코드 목록
    educatBackgroundStatus: [
        { code: '2yearCollege', codeDesc: '전문학사' },
        { code: '4yearCollege', codeDesc: '학사' },
        { code: 'mastersCompleted', codeDesc: '석사수료' },
        { code: 'mastersGraduated', codeDesc: '석사졸업' },
        { code: 'doctorsCompleted', codeDesc: '박사수료' },
        { code: 'doctorsGraduated', codeDesc: '박사졸업' },
    ],

    // 센터 소속 분류 코드 목록
    centerStatus: [
        { code: 1, codeDesc: '내부(네오폰스 소속)' },
        { code: 2, codeDesc: '협회' },
        { code: 3, codeDesc: '기타' },
    ],

    // 가입 승인 상태 코드 목록
    status: [
        { code: 0, codeDesc: '승인대기', color: 'info' },
        { code: 1, codeDesc: '승인됨', color: 'success' },
        { code: 2, codeDesc: '이용정지', color: 'error', variant: 'outlined' },
        { code: 3, codeDesc: '탈퇴', color: 'warning', variant: 'outlined' },
        { code: 4, codeDesc: '승인거부', color: 'error', variant: 'outlined' },
    ],

    // 비대면 수업 신청 상태 코드
    untactLessonStatus: [
        { code: 1, codeDesc: '승인됨', color: 'success', variant: 'filled' },
        { code: 2, codeDesc: '승인대기', color: 'info', variant: 'filled' },
        { code: 3, codeDesc: '신청안함', color: 'info', variant: 'outlined' },
    ],
    inflowPath: [
        { codeDesc: '지인 추천', code: 'acquaintance' },
        { codeDesc: '사이트 검색', code: 'site' },
        { codeDesc: 'SNS', code: 'sns' },
        { codeDesc: '블로그', code: 'blog' },
        { codeDesc: '카페', code: 'cafe' },
        { codeDesc: '기타', code: 'etc' },
    ],
    char: [
        { codeDesc: '엄격한', code: 'strict' },
        { codeDesc: '친근한', code: 'friendly' },
        { codeDesc: '친절한', code: 'kindHearted' },
        { codeDesc: '정적인', code: 'static' },
        { codeDesc: '발랄한', code: 'lively' },
        { codeDesc: '활동적인', code: 'active' },
    ],
    curableArea: [
        { codeDesc: '언어발달 장애', code: 'speechDevDis' },
        { codeDesc: '말소리 장애', code: 'tonVoiceDis' },
        { codeDesc: '신경언어 장애', code: 'neurolicDis' },
        { codeDesc: '유창성 장애', code: 'fluencyDis' },
        { codeDesc: '음성 장애', code: 'speechDis' },
    ],
    wishGender: [
        { codeDesc: '여성', code: 'female' },
        { codeDesc: '남성', code: 'male' },
    ],
    wishTalky: [
        { codeDesc: '성인', code: 'adult' },
        { codeDesc: '아동', code: 'kid' },
    ],
    dayOfWeek: [
        { codeDesc: '월요일', code: 'mon' },
        { codeDesc: '화요일', code: 'tue' },
        { codeDesc: '수요일', code: 'wed' },
        { codeDesc: '목요일', code: 'thu' },
        { codeDesc: '금요일', code: 'fri' },
        { codeDesc: '토요일', code: 'sat' },
        { codeDesc: '일요일', code: 'sun' },
    ],
};
