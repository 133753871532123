export const transDay = day => {
    switch (day) {
        case 'mon':
            day = '월요일';
            break;
        case 'tue':
            day = '화요일';
            break;
        case 'wed':
            day = '수요일';
            break;
        case 'thu':
            day = '목요일';
            break;
        case 'fri':
            day = '금요일';
            break;
        case 'sat':
            day = '토요일';
            break;
        case 'sun':
            day = '일요일';
            break;
        default:
    }
    return day;
};

export const transTalky = arr => {
    let newArray = [];
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === 'kid') {
            newArray.push('어린이');
        }
        if (arr[i] === 'adult') {
            newArray.push('성인');
        }
    }
    return newArray.join(',');
};

export const transChar = arr => {
    let newArray = [];
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === 'strict') {
            newArray.push('엄격한');
        }
        if (arr[i] === 'friendly') {
            newArray.push('친근한');
        }
        if (arr[i] === 'kindHearted') {
            newArray.push('친절한');
        }
        if (arr[i] === 'static') {
            newArray.push('정적인');
        }
        if (arr[i] === 'lively') {
            newArray.push('발랄한');
        }
        if (arr[i] === 'active') {
            newArray.push('활동적인');
        }
    }
    return newArray.join(',');
};

export const transCurableArea = arr => {
    let newArray = [];
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === 'speechDevDis') {
            newArray.push('언어발달 장애');
        }
        if (arr[i] === 'tonVoiceDis') {
            newArray.push('말소리 장애');
        }
        if (arr[i] === 'neurolicDis') {
            newArray.push('신경언어 장애');
        }
        if (arr[i] === 'fluencyDis') {
            newArray.push('유창성 장애');
        }
        if (arr[i] === 'speechDis') {
            newArray.push('음성 장애');
        }
    }
    return newArray.join(',');
};

export const transGender = arr => {
    let newArray = [];
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === 'male') {
            newArray.push('남성');
        }
        if (arr[i] === 'female') {
            newArray.push('여성');
        }
    }
    return newArray.join(',');
};

// table handling
export const handleGender = v => {
    if (v === 'male') return '남';
    if (v === 'female') return '여';
};

// contactInfo handling
export const handleContactInfoArray = arr => {
    // Todo : 백엔드 키값 확인하기 -> relationship,type 등

    let result = '본인 : 010-1234-5678';

    // for (let i = 0; i < arr.length; i++) {
    //     let info = `${arr[i].relationship} : ${arr[i].contact}`;
    //     let type = arr[i].type ? `(${arr[i].type})` : '';
    //
    //     result += info + type;
    // }

    return result;
};

// wishTimeArray handling
export const handleWishTimeArray = arr => {
    // end start dayOfWeek
    let result = '목 09:00 - 09:30';

    // for (let i = 0; i < arr.length; i++) {
    //     let info = `${arr[i].dayOfWeek} ${arr[i].start} - ${arr[i].end}`;
    //
    //     result += info;
    // }

    return result;
};

// wishGender handling
export const handleWishGenderArray = arr => {
    let result = '';

    for (let i = 0; i < arr.length; i++) {
        if (i >= 1) {
            result += ',' + handleGender(arr[i]);
        } else {
            result += handleGender(arr[i]);
        }
    }
    return result;
};

// wishChar handling
export const handleWishCharArray = arr => {
    let result = '';
    function transChar(v) {
        if (v === 'strict') return '엄격한';
        if (v === 'friendly') return '친근한';
        if (v === 'kindHearted') return '친절한';
        if (v === 'static') return '정적인';
        if (v === 'lively') return '발랄한';
        if (v === 'active') return '활동적인';
    }
    for (let i = 0; i < arr.length; i++) {
        if (arr.length === 1) {
            return transChar(arr[i]);
        } else if (i < arr.length - 1) {
            result += transChar(arr[i]) + ',';
        } else {
            result += transChar(arr[i]);
        }
    }

    return result;
};

// 나중에 utils 폴더로 옮기기:
export const formatPhoneNumber = str => {
    // 카카오톡 아이디일수도 있으므로 숫자인지 확인:
    if (!isNaN(str)) {
        switch (str.length) {
            case 9:
                return str.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');

            case 10:
                return str.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

            case 11:
                return str.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

            default:
                return str;
        }
    } else return str;
};

// 텍스트 하이라이트
export const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = (text || '').split(new RegExp(`(${highlight})`, 'gi'));

    return (
        <span>
            {parts.map((part, i) => (
                <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                    {part}
                </span>
            ))}
        </span>
    );
};
