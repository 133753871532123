import React, { useEffect, useState } from 'react';

import { Pagination } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { CardWrapper, InfoWrapper, ListWrapper, Divider, Title } from './UserList.style';
import { formatPhoneNumber, transChar, transCurableArea, transDay, transTalky, transGender } from '../../utils/util';
import { callAdminTalkyListAPI, callAdminTalkyInfoAPI } from '../../apis/api';

export const TalkyList = () => {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    const [selected, setSelected] = useState(null);
    const [selectedTalky, setSelectedTalky] = useState();

    const [open, setOpen] = React.useState(false);

    const [keyword, setKeyword] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '65%',
        bgcolor: 'background.paper',
        borderRadius: '5px',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        callAdminTalkyListAPI(currentPage, keyword)
            .then(res => {
                const { total_count, total_page, items } = res.data.data;
                setCurrentPage(currentPage);
                setTotalPage(total_page);
                setTotalCount(total_count);
                setItems(items);
            })
            .catch(e => {});
    }, [currentPage, keyword]);

    const handlePagination = (e, v) => {
        e.persist();
        setCurrentPage(v);
    };

    const handleTalkyInfoAPI = id => {
        setSelected(id);
        callAdminTalkyInfoAPI(id)
            .then(res => {
                setSelectedTalky(res.data.data);
            })
            .then(res => {
                handleOpen();
            });
    };

    return (
        <>
            <h3>토키 : {totalCount}명</h3>
            <p>이름,아이디,이메일로 검색할 수 있습니다.</p>
            <TextField
                id='outlined-basic'
                label='검색'
                variant='outlined'
                onChange={e => setKeyword(e.target.value)}
                style={{ marginBottom: '25px' }}
            />
            <ListWrapper>
                {items.map((item, idx) => {
                    return (
                        <CardWrapper key={idx}>
                            <Card sx={{}} style={{ marginBottom: '25px', minHeight: '15px' }}>
                                <CardContent>
                                    <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
                                        {item.userName}
                                    </Typography>
                                    <Typography variant='h5' component='div'>
                                        {item.fullName}
                                    </Typography>
                                    <Typography variant='body2' style={{ height: '40px', overflow: 'hidden' }}>
                                        {item.majorAppealSymptoms}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size='small' onClick={() => handleTalkyInfoAPI(item.id)}>
                                        자세히보기
                                    </Button>
                                </CardActions>
                            </Card>
                        </CardWrapper>
                    );
                })}
            </ListWrapper>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                style={{ overflowY: 'initial' }}
            >
                <Box sx={style}>
                    <Typography id='modal-modal-title' variant='h6' component='h2'>
                        토키 정보
                    </Typography>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }} component={'div'}>
                        <InfoWrapper>
                            <Avatar
                                alt='talky_photo'
                                src={selectedTalky?.userPhoto}
                                style={{
                                    width: '120px',
                                    height: '120px',
                                    marginBottom: '25px',
                                }}
                            />
                            <Divider>
                                <Title>기본 신상</Title>
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='이름'
                                    defaultValue={selectedTalky?.fullName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='아이디'
                                    defaultValue={selectedTalky?.userName}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='성별'
                                    defaultValue={selectedTalky?.gender === 'female' ? '여성' : '남성'}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='국가'
                                    defaultValue={selectedTalky?.nation}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='생년월일'
                                    defaultValue={selectedTalky?.birthdate}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='나이'
                                    defaultValue={selectedTalky?.age}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='이메일'
                                    defaultValue={selectedTalky?.userEmail}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='연락처'
                                    defaultValue={formatPhoneNumber(selectedTalky?.contactInfoArray[0].contact)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />{' '}
                                <TextField
                                    style={{ margin: 10, width: '100%' }}
                                    id='outlined-read-only-input'
                                    label='주소'
                                    defaultValue={selectedTalky?.address1 + '' + selectedTalky?.address2}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <TextField
                                    style={{ margin: 10, width: '250px' }}
                                    id='outlined-read-only-input'
                                    label='우편번호'
                                    defaultValue={selectedTalky?.zipcode}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Divider>
                            <Divider>
                                <Title>치료 정보</Title>

                                {selectedTalky?.wishChar && (
                                    <TextField
                                        style={{ margin: 10 }}
                                        id='outlined-read-only-input'
                                        label='선호치료사성향'
                                        defaultValue={transChar(selectedTalky.wishChar)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                )}

                                {selectedTalky?.majorAppealSymptoms && (
                                    <TextField
                                        style={{ margin: 10, width: '100%' }}
                                        id='outlined-read-only-input'
                                        label='기록정보'
                                        defaultValue={selectedTalky.majorAppealSymptoms}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                )}

                                {selectedTalky?.wishTimeArray.map((time, idx) => {
                                    return (
                                        <TextField
                                            key={idx}
                                            style={{ margin: 10, width: '100%' }}
                                            id='outlined-read-only-input'
                                            label={`선호시간${idx + 1}`}
                                            defaultValue={`[${transDay(time.dayOfWeek)}] ${time.startTime} - ${time.endTime}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    );
                                })}

                                {selectedTalky?.wishTimeArray.map((time, idx) => {
                                    return (
                                        <TextField
                                            key={idx}
                                            style={{ margin: 10, width: '100%' }}
                                            id='outlined-read-only-input'
                                            label={`선호시간${idx + 1}`}
                                            defaultValue={`[${transDay(time.dayOfWeek)}] ${time.startTime} - ${time.endTime}`}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    );
                                })}
                            </Divider>
                        </InfoWrapper>
                    </Typography>
                </Box>
            </Modal>
            <Pagination
                style={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}
                count={totalPage}
                page={currentPage}
                onChange={handlePagination}
                siblingCount={1}
            />
        </>
    );
};
