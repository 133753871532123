import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { GeneratorsWrapper } from './Coupon.style';
import { callAdminRegister } from '../../apis/api';

export const CouponRegister = () => {
    // Todo : key-value 정확히 알기!
    const handleRegister = () => {
        let temp = {
            type: '5',
            product_id: '2',
            publish_type: '언어치료',
            name: '100% 할인 쿠폰',
            rate: '100',
            amount: '',
            expiration_date: '2022-05-05',
            expiration_day: '',
        };

        callAdminRegister(temp).then(res => {
            // console.log(res);
        });
    };
    return (
        <GeneratorsWrapper>
            {/*<TextField id="outlined-basic" label="쿠폰 이름" variant="outlined" />*/}
            {/*<TextField id="outlined-basic" label="할인 비율" variant="outlined" />*/}
            {/*<TextField id="outlined-basic" label="할인 금액" variant="outlined" />*/}
            {/*<TextField id="outlined-basic" label="적용 상품 id" variant="outlined" />*/}
            {/*<TextField id="outlined-basic" label="만료기한" variant="outlined" />*/}

            <Button variant='contained' onClick={handleRegister}>
                쿠폰 정보 등록 하기
            </Button>
        </GeneratorsWrapper>
    );
};
