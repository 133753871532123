import { authHeader } from '../utils/auth';
import { Axios } from '../components/common/AxiosInterceptor';

// admin 로그인 api
export const callAdminLoginAPI = (adminName, password) => {
    return Axios.put(`/admin/login`, { adminName, password });
};

// admin 리프레시 토큰
export const callRefreshToken = () => {
    return Axios.get('/admin/login/refreshtoken', { headers: authHeader() });
};

// 토키목록 불러오기 api
export const callAdminTalkyListAPI = (page, keyword) => {
    return Axios.get(`/admin/talky/list`, {
        params: {
            page,
            keyword,
        },
        headers: authHeader(),
    });
};

// 센터 목록 불러오기 api
export const callCenterListAPI = async () => {
    const response = await Axios.get('/admin/center', { headers: authHeader() });
    return response.data;
};

// 센터 목록 불러오기 api
export const callCenterInfoAPI = async centerId => {
    const response = await Axios.get(`/admin/center/${centerId}`, { headers: authHeader() });
    return response.data;
};

// 센터 목록 불러오기 api
export const callCenterRegisterAPI = async data => {
    const response = await Axios.post(`/admin/center`, data, { headers: authHeader() });
    return response.data;
};

// 센터 목록 불러오기 api
export const callCenterUpdateAPI = async data => {
    const centerId = data.centerId;
    const response = await Axios.patch(`/admin/center/${centerId}`, data, { headers: authHeader() });
    return response.data;
};

// 치료사 정보 불러오기 api
export const callAdminTalkyInfoAPI = idx => {
    return Axios.get(`/admin/talky/${idx}/info`, {
        headers: authHeader(),
    });
};

// 치료사 목록 불러오기 api
export const callAdminTherapistListAPI = async () => {
    const response = await Axios.get(`/admin/therapist/list`, {
        // params: {
        //     page,
        //     keyword,
        // },
        headers: authHeader(),
    });
    return response.data;
};

// 치료사 정보 불러오기 api
export const callAdminTherapistInfoAPI = async idx => {
    const response = await Axios.get(`/admin/therapist/${idx}`, { headers: authHeader() });
    return response.data;
};

// 치료사 승인 및 거절 api
export const callAdminTherapistApprovalAPI = async ({ id, approved, denyReason }) => {
    const response = await Axios.patch(`/admin/therapist/${id}/approval`, { approved, denyReason }, { headers: authHeader() });
    return response.data;
};

// 치료사 비대면 수업 승인 및 거절 api
export const callAdminTherapistUntactApprovalAPI = async ({ id, untactLessonApproved }) => {
    const response = await Axios.patch(`/admin/therapist/${id}/untact`, { untactLessonApproved }, { headers: authHeader() });
    return response.data;
};

// 치료사 비대면 수업 승인 및 거절 api
export const callAdminTherapistUpdateAPI = async ({ id, data }) => {
    const response = await Axios.patch(`/admin/therapist/${id}`, { ...data }, { headers: authHeader() });
    return response.data;
};

// 치료사 비대면 수업 승인 및 거절 api
export const callAdminTherapistResetPasswordAPI = async id => {
    const response = await Axios.patch(`/admin/therapist/${id}/reset-password`, {}, { headers: authHeader() });
    return response.data;
};

// 노트 작성수 다운로드
export const callExportNoteCount = async () => {
    const response = await Axios.get('/admin/therapist/export-note-count', { headers: authHeader(), responseType: 'blob' });
    return response.data;
};

// 공지사항 등록
export const callAdminNoticeAPI = (title, content) => {
    let data = {
        board_id: 'notice',
        category_idx: '2',
        title,
        content,
    };

    return Axios.post(`/board/register`, data, {
        headers: authHeader(),
    });
};

// 1대1 문의 목록보기
export const callAdminInquiryAPI = (page, keyword) => {
    return Axios.get(`/board/list`, {
        params: {
            board_id: 'inquiry',
            page,
            keyword,
        },
        headers: authHeader(),
    });
};

// 1대1 문의 답변하기
export const callAdminReply = (idx, content) => {
    let data = {
        board_id: 'inquiry',
        idx,
        content,
    };

    return Axios.post(`/board/register/reply`, data, {
        headers: authHeader(),
    });
};

// 쿠폰 목록 api
export const callAdminCouponList = () => {
    return Axios.get(`/admin/coupon/list`, { headers: authHeader() });
};

// 쿠폰 등록(생성) api
export const callAdminRegister = data => {
    return Axios.post(`/admin/coupon/register`, data, { headers: authHeader() });
};
// 쿠폰 발행 api
export const callAdminGenerator = (coupon_id, count) => {
    return Axios.post(
        `/admin/coupon/publish`,
        {
            coupon_id,
            count,
        },
        { headers: authHeader() },
    );
};
