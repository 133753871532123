import React, { useCallback } from 'react';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DownloadOutlined, RestartAlt, Search } from '@mui/icons-material';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { CODE_DICT } from '../../utils/const';
import { callExportNoteCount } from '../../apis/api';

// select option list
const getMenuItemList = codeList =>
    [{ code: 'ALL', codeDesc: '전체' }, ...codeList].map(v => (
        <MenuItem key={v.code} value={v.code}>
            {v.codeDesc}
        </MenuItem>
    ));

// react-hook-form + material ui Select
const FormSelect = ({ control, name, label, optionList, width }) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ m: 1, width }}>
                    <InputLabel id={`${name}Label`} size='small'>
                        {label}
                    </InputLabel>
                    <Select labelId={`${name}Label`} id={name} label={label} size='small' onChange={onChange} value={value}>
                        {optionList}
                    </Select>
                </FormControl>
            )}
        />
    );
};

// 치료사 테이블 검색 필터
export default function CenterSearchHeader({ setFilterData, handleOpenInsertModal }) {
    const { control, handleSubmit, reset } = useFormContext();

    // 필터 검색
    const handleSearch = useCallback(
        data => {
            setFilterData(data);
        },
        [setFilterData],
    );

    // 필터 초기화
    const handleReset = useCallback(() => {
        reset(); // 폼 초기화
        setFilterData({
            centerStatus: 'ALL',
            status: 'ALL',
            untactLessonStatus: 'ALL',
            keyword: '',
        });
    }, [setFilterData, reset]);

    return (
        <Box component='form' onSubmit={handleSubmit(handleSearch)} sx={{ display: 'flex', marginBottom: '25px' }}>
            <FormSelect
                control={control}
                name='status'
                label='센터 소속'
                optionList={getMenuItemList(CODE_DICT.centerStatus)}
                width={180}
            />
            <Controller
                control={control}
                name='keyword'
                render={({ field: { onChange, value } }) => (
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <TextField onChange={onChange} value={value} label='이름, 센터장, 주소, 연락처' size='small' />
                    </FormControl>
                )}
            />
            <IconButton type='submit' color='primary'>
                <Search />
            </IconButton>
            <IconButton onClick={handleReset}>
                <RestartAlt />
            </IconButton>
            <Button variant='contained' size='small' sx={{ m: 1 }} onClick={handleOpenInsertModal}>
                센터 등록
            </Button>
        </Box>
    );
}
