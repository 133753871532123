import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { CheckRounded, ClearRounded, Photo, Visibility } from '@mui/icons-material';
import { Controller, FormProvider, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useConfirm } from '../../context/confirm';
import { useSnackbar } from '../../context/snackbar';
import { CODE_DICT } from '../../utils/const';
import TherapistDenyModal from './TherapistDenyModal';
import { useCenterList } from '../../hooks/center';
import {
    useTherapistApproveMutation,
    useTherapistApproveUntactMutation,
    useTherapistDetail,
    useTherapistResetPasswordMutation,
    useTherapistUpdateMutation,
} from '../../hooks/therapist';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '650px',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    px: 4,
    py: 2,
};

const startTimeList = ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'];
const endTimeList = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'];

const getMenuItemList = codeList =>
    codeList.map(v => (
        <MenuItem key={v.code} value={v.code}>
            {v.codeDesc}
        </MenuItem>
    ));

// mui Select + react-hook-form
const FormSelect = ({ name, label, optionList, width, ...rest }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ m: 1, width }}>
                    <InputLabel id={`${name}Label`} size='small'>
                        {label}
                    </InputLabel>
                    <Select labelId={`${name}Label`} id={name} label={label} size='small' onChange={onChange} value={value} {...rest}>
                        {getMenuItemList(optionList)}
                    </Select>
                </FormControl>
            )}
        />
    );
};

// mui Text Field + react-hook-form
const FormTextField = ({ name, label, width, readOnly, ...rest }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ m: 1, width }}>
                    <TextField onChange={onChange} value={value || ''} label={label} size='small' inputProps={{ readOnly }} {...rest} />
                </FormControl>
            )}
        />
    );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 258,
        },
    },
};

// mui multi-select + react-hook-form
export function FormMultiSelect({ name, label, width, optionList, ...rest }) {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <FormControl sx={{ m: 1, width }}>
                    <InputLabel id={`${name}Label`} size='small'>
                        {label}
                    </InputLabel>
                    <Select
                        labelId={`${name}Label`}
                        id={name}
                        label={label}
                        multiple
                        value={value || []}
                        onChange={onChange}
                        MenuProps={MenuProps}
                        size='small'
                        {...rest}
                    >
                        {getMenuItemList(optionList)}
                    </Select>
                </FormControl>
            )}
        />
    );
}

// 치료사 정보 영역 구분선
const Title = ({ children }) => (
    <Divider textAlign='left' sx={{ fontWeight: 'bold', marginTop: '20px' }}>
        {children}
    </Divider>
);

const getFileName = path =>
    path
        ?.replace(/^.*[\\/]/, '') // 파일 이름만 남김
        ?.replace(/\?[^?]*$/, ''); // querystring 삭제

// 치료사 정보 조회/수정 모달
const TherapistInfoModal = ({ open, handleClose, therapistId }) => {
    // 치료사 가입 거부 모달
    const [denyModalOpen, setDenyModalOpen] = useState(false);
    const handleOpenDenyModal = useCallback(() => {
        setDenyModalOpen(true);
    }, []);
    const handleCloseDenyModal = useCallback(() => {
        setDenyModalOpen(false);
    }, []);

    /* react-hook-form */
    const infoForm = useForm({
        defaultValues: {
            educatBackgroundArr: [], // 학력
            maintenanceEducatArr: [], // 보수교육
            careerArr: [], // 경력
            certificateArr: [], // 자격증
            wishTimeArray: [], // 희망 시간
        },
    });
    const { control, reset, register, resetField } = infoForm;
    const educatBackgroundArr = useFieldArray({ control, name: 'educatBackgroundArr' }); // 학력
    const careerArr = useFieldArray({ control, name: 'careerArr' }); // 경력
    const maintenanceEducatArr = useFieldArray({ control, name: 'maintenanceEducatArr' }); // 보수교육 정보
    const certificateArr = useFieldArray({ control, name: 'certificateArr' }); // 자격증
    const wishTimeArray = useFieldArray({ control, name: 'wishTimeArray' }); // 치료가능시간
    const watchUserPhoto = useWatch({ control, name: 'userPhoto' }); // 프로필 사진
    const watchUntactLessonApproved = useWatch({ control, name: 'untactLessonApproved' }); // 비대면 수업 승인 여부
    const watchCenterStatus = useWatch({ control, name: 'centerStatus' });

    const { showConfirm } = useConfirm(); // 컨펌 모달
    const { showMessage } = useSnackbar(); // 메시지

    /* react-query */
    const queryClient = useQueryClient();
    // 센터 목록 GET
    const { data: centerData } = useCenterList();

    // 필터된 센터 목록
    const centerList = useMemo(
        () => centerData?.data?.filter(v => v.status === watchCenterStatus)?.map(v => ({ code: v.centerId, codeDesc: v.name })),
        [centerData, watchCenterStatus],
    );

    // 치료사 정보 GET
    const { data, isFetching } = useTherapistDetail(therapistId, reset);

    // 치료사 비대면 승인 취소 mutation
    const approveMutation = useTherapistApproveMutation(therapistId, showMessage);
    // 치료사 비대면 승인 mutation
    const approveUntactMutation = useTherapistApproveUntactMutation(therapistId, showMessage);
    // 치료사 정보 수정 mutation
    const updateMutation = useTherapistUpdateMutation(therapistId, showMessage);
    const resetPasswordMutation = useTherapistResetPasswordMutation(therapistId, showMessage);

    //  치료사 가입 승인
    const handleApprove = useCallback(() => {
        showConfirm({
            title: '가입 승인',
            contentText: '치료사 가입을 승인하시겠습니까?',
            onOk: () => {
                approveMutation.mutate({ id: therapistId, approved: 'Y' });
            },
        });
    }, [approveMutation, showConfirm, therapistId]);

    // 치료사 가입 거부
    const handleDeny = useCallback(
        denyReason => () => {
            approveMutation.mutate({ id: therapistId, approved: 'N', denyReason });
            handleCloseDenyModal(); // 모달 닫기
        },
        [approveMutation, therapistId, handleCloseDenyModal],
    );

    // 비대면 승인 버튼 클릭
    const handleApproveUntact = useCallback(() => {
        showConfirm({
            title: '비대면 승인',
            contentText: '비대면 수업을 승인하시겠습니까?',
            onOk: () => {
                approveUntactMutation.mutate({ id: therapistId, untactLessonApproved: 'Y' });
            },
        });
    }, [approveUntactMutation, showConfirm, therapistId]);
    // 비대면 승인 취소 버튼 클릭
    const handleCancelUntact = useCallback(() => {
        showConfirm({
            title: '비대면 취소',
            contentText: '비대면 수업 승인을 취소하시겠습니까?',
            onOk: () => {
                approveUntactMutation.mutate({ id: therapistId, untactLessonApproved: 'N' });
            },
        });
    }, [approveUntactMutation, showConfirm, therapistId]);

    //  치료사 비밀번호 초기화
    const handleResetPassword = useCallback(() => {
        showConfirm({
            title: '초기화',
            contentText: '치료사 비밀번호를 초기화하시겠습니까?',
            onOk: () => {
                resetPasswordMutation.mutate(therapistId);
            },
        });
    }, [resetPasswordMutation, showConfirm, therapistId]);

    // 모달 닫기
    const handleOnClose = useCallback(() => {
        // useFieldArray 쓴 건 따로 reset해줘야 됨
        resetField('careerArr');
        resetField('certificateArr');
        resetField('educatBackgroundArr');
        resetField('maintenanceEducatArr');
        resetField('wishTimeArray');

        // 모달 닫기
        handleClose();
    }, [resetField, handleClose]);

    // 치료사 정보 수정
    const handleUpdate = useCallback(
        formData => {
            const { gender, userEmail, birthdate, centerStatus, isChief } = formData;
            const affiliation = centerStatus === 3 ? formData.affiliation : centerList.find(v => v.code === formData.centerId).codeDesc; // 센터 이름 (소속이 기타일 땐 직접 입력한 값으로 받음)
            const centerId = centerStatus === 3 ? 9999 : formData.centerId; // 센터 ID (소속이 기타일 땐 9999)
            console.log(affiliation);

            const submitData = { gender, userEmail, birthdate, centerStatus, centerId, affiliation, isChief }; // submit data

            // 확인 모달
            showConfirm({
                title: '정보 수정',
                contentText: '치료사 정보를 수정하시겠습니까?',
                onOk: () => {
                    updateMutation.mutate({ id: therapistId, data: submitData });
                },
            });
        },
        [centerList, showConfirm, therapistId, updateMutation],
    );

    // 모달 열 때마다 refetch
    useEffect(() => {
        if (open) {
            queryClient.refetchQueries(['therapist', therapistId]);
        }
    }, [open, therapistId, queryClient]);

    return (
        <>
            <Modal open={open} onClose={handleOnClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <div>
                    <Box sx={style}>
                        <Typography id='modal-modal-title' variant='h6' component='h2'>
                            치료사 정보
                        </Typography>
                        <FormProvider {...infoForm}>
                            <form onSubmit={infoForm.handleSubmit(handleUpdate)}>
                                <Box id='modal-modal-description'>
                                    <Box sx={{ mt: 2, height: 388, overflowY: 'auto' }}>
                                        {isFetching ? (
                                            <CircularProgress />
                                        ) : (
                                            <>
                                                <input {...register('userPhoto')} disabled style={{ display: 'none' }} />
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Avatar
                                                            alt='therapist_photo'
                                                            src={watchUserPhoto}
                                                            sx={{ width: 120, height: 120 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container direction='column' justifyContent='space-evenly' height='100%'>
                                                            <Grid item>
                                                                <Typography variant='body1' component='span' sx={{ mr: 1 }}>
                                                                    회원 상태:{' '}
                                                                    {CODE_DICT.status.find(v => v.code === data?.data?.status)?.codeDesc}
                                                                </Typography>
                                                                <Button
                                                                    variant='outlined'
                                                                    sx={{ mr: 1 }}
                                                                    color='success'
                                                                    onClick={handleOpenDenyModal}
                                                                    disabled={data?.data?.status === 4} // 승인 거부 상태
                                                                >
                                                                    거부
                                                                </Button>
                                                                <Button
                                                                    variant='contained'
                                                                    sx={{ mr: 1 }}
                                                                    color='success'
                                                                    onClick={handleApprove}
                                                                    disabled={data?.data?.status === 1} // 승인 상태
                                                                >
                                                                    승인
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant='outlined' color='error' onClick={handleResetPassword}>
                                                                    비밀번호 초기화
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Title>기본 신상</Title>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <FormTextField name='fullName' label='이름' width={258} disabled />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormSelect name='gender' label='성별' width={258} optionList={CODE_DICT.gender} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormTextField name='userName' label='아이디' width={258} disabled />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormTextField name='userEmail' label='이메일' width={258} />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <FormTextField name='birthdate' label='생년월일' width={258} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormTextField name='age' label='나이' width={258} disabled />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormSelect
                                                            name='centerStatus'
                                                            label='센터 소속'
                                                            width={258}
                                                            optionList={CODE_DICT.centerStatus}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {watchCenterStatus === 3 ? (
                                                            <FormTextField name='affiliation' label='센터명' width={258} />
                                                        ) : (
                                                            <FormSelect
                                                                name='centerId'
                                                                label='센터명'
                                                                width={258}
                                                                optionList={centerList}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormSelect
                                                            name='isChief'
                                                            label='센터장 여부'
                                                            width={258}
                                                            optionList={[
                                                                { code: 'Y', codeDesc: '예' },
                                                                { code: 'N', codeDesc: '아니오' },
                                                            ]}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Title>학력</Title>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <FormSelect
                                                            name='educatBackgroundStatus'
                                                            label='최종학력'
                                                            width={258}
                                                            optionList={CODE_DICT.educatBackgroundStatus}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}></Grid>
                                                    <Grid item xs={6}>
                                                        {educatBackgroundArr.fields.map((field, idx) => {
                                                            return (
                                                                <FormTextField
                                                                    key={field.id}
                                                                    name={`educatBackgroundArr.${idx}.name`}
                                                                    label={`학력${idx + 1}`}
                                                                    width={258}
                                                                    disabled
                                                                />
                                                            );
                                                        })}
                                                    </Grid>
                                                </Grid>
                                                <Title>경력</Title>
                                                <Grid container>
                                                    {careerArr.fields.map((field, idx) => {
                                                        return (
                                                            <React.Fragment key={field.id}>
                                                                <Grid item xs={6}>
                                                                    <FormTextField
                                                                        name={`careerArr.${idx}.orgName`}
                                                                        label={`이름`}
                                                                        width={258}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <FormTextField
                                                                        name={`careerArr.${idx}.startDate`}
                                                                        label={`시작일자`}
                                                                        width={120}
                                                                        disabled
                                                                    />
                                                                    <FormTextField
                                                                        name={`careerArr.${idx}.endDate`}
                                                                        label={`종료일자`}
                                                                        width={120}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Grid>

                                                <Title>자격증</Title>
                                                <Grid container>
                                                    {certificateArr.fields.map((field, idx) => {
                                                        return (
                                                            <React.Fragment key={field.id}>
                                                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <FormTextField
                                                                        name={`certificateArr.${idx}.certName`}
                                                                        label={`자격증 이름`}
                                                                        width={258}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <FormControl sx={{ m: 1, width: 210 }}>
                                                                        <TextField
                                                                            value={getFileName(field.certFile)}
                                                                            label='첨부 파일'
                                                                            size='small'
                                                                            inputProps={{ readOnly: true }}
                                                                            disabled
                                                                        />
                                                                    </FormControl>
                                                                    <IconButton
                                                                        color='primary'
                                                                        onClick={() => window.open(field.certFile)}
                                                                        disabled={!field.certFile}
                                                                    >
                                                                        <Photo />
                                                                    </IconButton>
                                                                </Grid>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Grid>
                                                <Title>치료 정보</Title>
                                                <FormMultiSelect
                                                    name='curableArea'
                                                    label='치료가능영역'
                                                    width={258}
                                                    optionList={CODE_DICT.curableArea}
                                                    disabled
                                                />
                                                <Typography variant='body2' sx={{ mt: 1, ml: 1, fontWeight: 'bold' }}>
                                                    치료가능시간
                                                </Typography>
                                                <Grid container>
                                                    {wishTimeArray.fields.map((field, idx) => {
                                                        return (
                                                            <React.Fragment key={field.id}>
                                                                <Grid item xs={6}>
                                                                    <FormSelect
                                                                        name={`wishTimeArray.${idx}.dayOfWeek`}
                                                                        label={`선호요일`}
                                                                        width={258}
                                                                        optionList={CODE_DICT.dayOfWeek}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <FormSelect
                                                                        name={`wishTimeArray.${idx}.startTime`}
                                                                        label={`시작시간`}
                                                                        width={120}
                                                                        optionList={startTimeList.map(v => ({ code: v, codeDesc: v }))}
                                                                        disabled
                                                                    />
                                                                    <FormSelect
                                                                        name={`wishTimeArray.${idx}.endTime`}
                                                                        label={`종료시간`}
                                                                        width={120}
                                                                        optionList={endTimeList.map(v => ({ code: v, codeDesc: v }))}
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Grid>

                                                <Title>비대면 치료 정보</Title>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <FormTextField name='untactLesson' label='비대면 수업 신청' width={258} disabled />
                                                    </Grid>

                                                    {maintenanceEducatArr.fields.map((field, idx) => {
                                                        return (
                                                            <React.Fragment key={field.id}>
                                                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <FormControl sx={{ m: 1, width: 210 }}>
                                                                        <TextField
                                                                            value={getFileName(field.uploadFile)}
                                                                            label='보수교육 파일'
                                                                            size='small'
                                                                            inputProps={{ readOnly: true }}
                                                                            disabled
                                                                        />
                                                                    </FormControl>
                                                                    <IconButton
                                                                        onClick={() => window.open(field.uploadFile)}
                                                                        color='primary'
                                                                        disabled={!field.uploadFile}
                                                                    >
                                                                        <Photo />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    {watchUntactLessonApproved === 'Y' ? (
                                                                        <Button
                                                                            variant='contained'
                                                                            color='success'
                                                                            onClick={handleCancelUntact}
                                                                            sx={{ ml: 1 }}
                                                                        >
                                                                            비대면 승인됨
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            variant='contained'
                                                                            onClick={handleApproveUntact}
                                                                            sx={{ ml: 1 }}
                                                                        >
                                                                            비대면 승인
                                                                        </Button>
                                                                    )}
                                                                </Grid>
                                                            </React.Fragment>
                                                        );
                                                    })}

                                                    <Grid item xs={6}>
                                                        <FormMultiSelect
                                                            name='wishTalky'
                                                            label='선호토키'
                                                            width={258}
                                                            optionList={CODE_DICT.wishTalky}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormMultiSelect
                                                            name='char'
                                                            label='본인성향'
                                                            width={258}
                                                            optionList={CODE_DICT.char}
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </Box>

                                    <Box sx={{ mt: 2, textAlign: 'right' }}>
                                        <Button variant='outlined' sx={{ mr: 1 }} onClick={handleOnClose}>
                                            닫기
                                        </Button>
                                        <Button variant='contained' sx={{ mr: 1 }} type='submit'>
                                            수정
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </FormProvider>
                    </Box>
                </div>
            </Modal>
            <TherapistDenyModal open={denyModalOpen} handleOk={handleDeny} handleCancel={handleCloseDenyModal} />
        </>
    );
};

export default TherapistInfoModal;
