import React from 'react';

import { ContentWrapper, WelcomeImg } from './Welcome.style';
import { CDN_URL } from '../../utils/const';
export const Welcome = () => {
    return (
        <>
            <div>
                <h2>
                    안녕하세요! <br />
                    토키토키 관리자 페이지입니다.
                </h2>
                <h3>공사중입니다 :)</h3>
            </div>
            <ContentWrapper>
                <WelcomeImg src={`${CDN_URL}/test/01_t.png`} CDN_URL />
            </ContentWrapper>
        </>
    );
};
