import { createContext, useContext, useState } from 'react';
import CommonSnackbar from '../components/common/CommonSnackbar';

/**
 * @type {{
 *  open: boolean,
 *  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
 *  showMessage: (content: string | boolean, severity: import('@mui/material').AlertColor),
 *  severity: import('@mui/material').AlertColor,
 *  message: string,
 * }}
 */
const defaultContext = {};

export const SnackbarContext = createContext(defaultContext);

export const useSnackbar = () => {
    return useContext(SnackbarContext);
};

// 공통 snackbar provider
export const SnackbarProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    const showMessage = (content = false, severity = 'info') => {
        setSeverity(severity);
        if (content) {
            setMessage(content);
        }
        setOpen(!open);
    };

    return (
        <SnackbarContext.Provider value={{ showMessage, open, setOpen, severity, message }}>
            <CommonSnackbar />
            {children}
        </SnackbarContext.Provider>
    );
};
