import React, { useState, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import IndexRoutes from './routes/IndexRoutes';
import { AuthContext } from './context/auth';
import { accessToken, setAccessToken } from './utils/auth';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from './context/snackbar';
import { ConfirmProvider } from './context/confirm';
import { AxiosInterceptor } from './components/common/AxiosInterceptor';

// 페이지 간 이동할 때, 반드시 각 페이지의 최상단 부분을 보여주게끔 함:
const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const queryClient = new QueryClient();

const App = () => {
    const [loading, setLoading] = useState(false);

    // access token이 있는지 확인하고 있으면 토큰으로 그 페이지를 보는데 필요한 api 호출하기.
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('adminUser')) || '');

    // 로그인 & 로그아웃 시에 호출됨:
    const handleUser = data => {
        if (data) {
            // 로그인:
            localStorage.setItem('adminUser', JSON.stringify(data)); // 작업 후 삭제
            setAccessToken(data.accessToken);
        } else {
            // 로그아웃:
            localStorage.removeItem('adminUser'); // 작업 후 삭제
        }
        setUser(data);
    };

    const value = useMemo(() => ({ user, setUser: handleUser, accessToken: user.accessToken }), [user]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <AuthContext.Provider value={value}>
                <SnackbarProvider>
                    <ConfirmProvider>
                        <AxiosInterceptor>
                            <Router>
                                <ScrollToTop />
                                <IndexRoutes />
                            </Router>
                        </AxiosInterceptor>
                    </ConfirmProvider>
                </SnackbarProvider>
            </AuthContext.Provider>
        </QueryClientProvider>
    );
};

export default App;
