import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { ContentArea, TitleArea, EtcArea, CategoryWrapper, Category } from '../notice/Notice.style';
import { callAdminReply } from '../../apis/api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
};

export const InquiryDetail = () => {
    const location = useLocation();
    const item = location.state.item;

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleReply = () => {
        callAdminReply(item?.idx, content)
            .then(res => {
                if (res.data.result === 'success') {
                    alert('답변 완료.');
                }
            })
            .catch(e => {
                alert('답변 실패.');
            });
    };
    const goToList = () => {
        window.location.assign(`/inquiry`);
    };
    return (
        <>
            <h3>1대1문의 확인</h3>
            <CategoryWrapper>
                <Category>
                    <h5>문의넘버</h5>
                    <EtcArea defaultValue={item.idx} readOnly />
                </Category>

                <Category>
                    <h5>문의유형</h5>
                    <EtcArea defaultValue={item.category_name} readOnly />
                </Category>

                <Category>
                    <h5>등록날짜</h5>
                    <EtcArea defaultValue={item.recdate} readOnly />
                </Category>

                <Category>
                    <h5>첨부파일</h5>
                    {item.file_arr?.map((file, idx) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center' }} key={idx}>
                                <h5>{`첨부파일${idx + 1} `}</h5>
                                <VisibilityIcon
                                    className={'certi-icon'}
                                    onClick={() => window.open(file.attachFile)}
                                    style={{ cursor: 'pointer', marginTop: '-8px', marginRight: '15px' }}
                                />
                            </div>
                        );
                    })}
                </Category>
            </CategoryWrapper>

            <h5>내용</h5>
            <ContentArea defaultValue={item.content} readOnly />
            <Button variant='contained' size='medium' onClick={() => handleOpen()}>
                답변하기
            </Button>
            <Button style={{ marginTop: '10px', backgroundColor: 'blue' }} variant='contained' size='medium' onClick={() => goToList()}>
                문의목록보기
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                style={{ overflowY: 'initial' }}
            >
                <Box sx={style}>
                    <h3>답변하기</h3>
                    <ContentArea defaultValue={''} onChange={e => setContent(e.target.value)} />
                    <Button variant='contained' size='medium' onClick={() => handleReply()} style={{ width: '100%' }}>
                        보내기
                    </Button>
                </Box>
            </Modal>
        </>
    );
};
