import { GeneratorsWrapper } from './Coupon.style';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { callAdminCouponList, callAdminGenerator } from '../../apis/api';

export const CouponGenerator = () => {
    const [list, setList] = useState();
    const [couponId, setCouponId] = useState(0);
    const [couponNum, setCouponNum] = useState(0);

    const handleChangeCouponId = event => {
        setCouponId(event.target.value);
    };

    const handleChangeCouponNum = event => {
        setCouponNum(event.target.value);
    };

    useEffect(() => {
        callAdminCouponList().then(res => {
            setList(res.data.data.items);
        });
    }, []);

    const generatorsCouponName = list?.map((c, i) => {
        return <MenuItem key={i} value={c.id}>{`${c.publish_type} ${c.name}`}</MenuItem>;
    });

    const handleGenerator = () => {
        callAdminGenerator(couponId, couponNum)
            .then(res => {
                if (res.data.result === 'success') {
                    alert('쿠폰 생성 완료');
                }
            })
            .catch(e => {
                alert('쿠폰 생성 실패');
            });
    };
    return (
        <GeneratorsWrapper>
            <FormControl sx={{ m: 1, minWidth: 250 }} size='small'>
                <InputLabel id='demo-select-small'>쿠폰 이름</InputLabel>
                <Select labelId='demo-select-small' id='demo-select-small' value={couponId} label='num' onChange={handleChangeCouponId}>
                    {generatorsCouponName}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100 }} size='small'>
                <InputLabel id='demo-select-small'>생성 수</InputLabel>
                <Select labelId='demo-select-small' id='demo-select-small' value={couponNum} label='num' onChange={handleChangeCouponNum}>
                    <MenuItem value={10}>{'10개'}</MenuItem>
                    <MenuItem value={30}>{'30개'}</MenuItem>
                    <MenuItem value={50}>{'50개'}</MenuItem>
                </Select>
            </FormControl>
            <Button variant='contained' onClick={handleGenerator}>
                쿠폰 생성하기
            </Button>
        </GeneratorsWrapper>
    );
};
