import { createContext, useContext, useState } from 'react';
import CommonConfirm from '../components/common/CommonConfirm';

/**
 * @typedef {Object} ConfirmModalData
 * @property {string} title 제목
 * @property {React.ReactNode?} content 모달 컴포넌트
 * @property {string?} contentText 모달 텍스트
 * @property {string?} okText ok버튼 텍스트
 * @property {string?} cancelText 취소버튼 텍스트
 * @property {() => void} onOk ok버튼 콜백
 */

/**
 * @type {{
 *  open: boolean,
 *  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
 *  modalData: ConfirmModalData,
 *  showConfirm: (modalData: ConfirmModalData | boolean) => void,
 * }}
 */
const defaultContext = {};

export const ConfirmContext = createContext(defaultContext);

export const useConfirm = () => {
    return useContext(ConfirmContext);
};

// 공통 confirm 모달 provider
export const ConfirmProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState({
        title: '',
        content: <></>,
        contentText: '',
        okText: '확인',
        cancelText: '취소',
        onOk: () => {},
    });

    const showConfirm = (modalData = false) => {
        if (modalData) {
            setModalData(modalData);
        }

        setOpen(!open);
    };

    return (
        <ConfirmContext.Provider value={{ showConfirm, open, setOpen, modalData }}>
            <CommonConfirm />
            {children}
        </ConfirmContext.Provider>
    );
};
