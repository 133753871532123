import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

// 치료사 승인 거부 모달
// input이 들어있어서, 기존의 CommonConfirm을 사용하지 않고 새로 작성함.
export default function TherapistDenyModal({ open, handleOk, handleCancel }) {
    // input
    const [text, setText] = useState('');
    const handleChangeText = useCallback(e => {
        setText(e.target.value);
    }, []);

    // 모달 닫기
    const handleClose = useCallback(() => {
        handleCancel();
        setText('');
    }, [handleCancel]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>가입 거부</DialogTitle>
            <DialogContent>
                <DialogContentText>치료사 가입을 거부하시겠습니까?</DialogContentText>
                <TextField
                    autoFocus
                    margin='dense'
                    label='거부 사유'
                    fullWidth
                    variant='standard'
                    value={text}
                    onChange={handleChangeText}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>취소</Button>
                <Button onClick={handleOk(text)}>확인</Button>
            </DialogActions>
        </Dialog>
    );
}
