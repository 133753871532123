import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Box,
    CssBaseline,
    AppBar as MuiAppBar,
    Drawer as MuiDrawer,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    Container,
    Paper,
} from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ExitToApp as ExitToAppIcon, Menu as MenuIcon } from '@mui/icons-material';
import { firstListItems, secondaryListItems, thirdListItems, lastListItems } from './ListItems';
import { Copyright } from '../Copyright';
import { useAuth } from '../../context/auth';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const mdTheme = createTheme();

export const AppLayout = props => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const { user, setUser } = useAuth();

    const { title, children } = props;

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const logout = () => {
        setUser('');
    };

    // 로그인 정보가 없으면 로그인 화면으로
    useEffect(() => {
        if (!user) {
            navigate('/');
        }
    }, [user, navigate]);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position='absolute' open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge='start'
                            color='inherit'
                            aria-label='open drawer'
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
                            {title}
                        </Typography>
                        <ExitToAppIcon onClick={logout} />
                    </Toolbar>
                </AppBar>
                <Drawer variant='permanent' open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>

                    {/*Menu*/}
                    <List>{firstListItems}</List>
                    <Divider />
                    <List>{secondaryListItems}</List>
                    <Divider />
                    <List>{thirdListItems}</List>
                    <Divider />
                    <List>{lastListItems}</List>
                </Drawer>
                <Box
                    component='main'
                    sx={{
                        backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth='false' sx={{ mt: 4, mb: 4 }}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'auto',
                            }}
                        >
                            {/*첫 화면 랜더링 페이지 스타일링*/}
                            {children}
                        </Paper>
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
};
