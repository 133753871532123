import React, { useEffect, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../../context';
import { API_URL } from '../../utils/const';
import { useSnackbar } from '../../context/snackbar';

// axios instance 생성
export const Axios = axios.create({
    baseURL: API_URL,
    timeout: 10000,
});

// 모든 axios 요청에 대해 토큰 만료 에러 예외처리
export const AxiosInterceptor = ({ children }) => {
    const { setUser } = useAuth();
    const { showMessage } = useSnackbar();

    // 첫 로딩 때 interceptor 세팅
    useEffect(() => {
        Axios.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                console.log(error?.code);
                console.error(error.response || error);

                const code = error?.code;
                const status = error.response?.status; // 상태 코드
                if (status === 401 && error.response.data.message === 'TokenExpiredError') {
                    // 토큰 만료
                    setUser('');
                } else if (code === 'ECONNABORTED' || status === 408) {
                    // 요청 시간 초과
                    showMessage('네트워크 상태를 확인해 주세요.', 'error');
                }

                return Promise.reject(error);
            },
        );
    }, []);

    return children;
};

// export default AxiosInterceptor;
