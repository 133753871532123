import styled from '@emotion/styled';
import breakpoints from '../../utils/breakpoints';

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const WelcomeImg = styled.img`
    width: 80%;
    height: 80%;

    @media (min-width: ${breakpoints.device.tablet}) {
        width: 50%;
        height: 50%;
    }

    @media (min-width: ${breakpoints.device.desktop}) {
        width: 30%;
        height: 30%;
    }
`;
