import React, { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Avatar, Box, Button, Container, CssBaseline, TextField, Typography, createTheme, ThemeProvider } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useSnackbar } from '../../context/snackbar';
import { Copyright } from '../Copyright';
import { useAuth } from '../../context/auth';
import { callAdminLoginAPI } from '../../apis/api';

// mui Text Field + react-hook-form
const FormTextField = ({ name, label, width, readOnly, ...rest }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <TextField onChange={onChange} value={value || ''} label={label} size='small' inputProps={{ readOnly }} {...rest} />
            )}
            rules={{
                required: `${label} 입력하세요.`,
            }}
        />
    );
};

const theme = createTheme();

export const Login = () => {
    // react-hook-form
    const formMethods = useForm({
        defaultValues: {
            userName: '', // 아이디
            password: '', // 비밀번호
        },
    });
    const {
        handleSubmit,
        formState: { errors },
    } = formMethods;

    const { user, setUser } = useAuth();
    const { showMessage } = useSnackbar();

    // 로그인
    const handleAdminLogin = useCallback(
        data => {
            const { userName, password } = data;
            callAdminLoginAPI(userName, password)
                .then(res => {
                    if (res.data.result === 'success') {
                        showMessage('로그인 성공');
                        setUser(res.data);
                        return <Navigate replace to={`/main`} />;
                    } else {
                        showMessage(res.data.message, 'error');
                    }
                })
                .catch(e => {
                    showMessage('로그인 실패', 'error');
                });
        },
        [setUser, showMessage],
    );

    if (user?.accessToken) {
        return <Navigate replace to={`/main`} />;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component='main' maxWidth='xs'>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        Sign in
                    </Typography>
                    <Box noValidate sx={{ mt: 1 }}>
                        <FormProvider {...formMethods}>
                            <form onSubmit={handleSubmit(handleAdminLogin)}>
                                <FormTextField
                                    name='userName'
                                    margin='normal'
                                    required
                                    fullWidth
                                    label='ID'
                                    autoComplete='text'
                                    autoFocus
                                />
                                <FormTextField
                                    name='password'
                                    margin='normal'
                                    required
                                    fullWidth
                                    label='Password'
                                    type='password'
                                    autoComplete='current-password'
                                />
                                <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                                    Sign In
                                </Button>
                            </form>
                        </FormProvider>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
};
