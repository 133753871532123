import React from 'react';
import { Snackbar, Alert as MuiAlert } from '@mui/material';
import { useSnackbar } from '../../context/snackbar';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

// 공통 snackbar (message) 컴포넌트
export default function CommonSnackbar() {
    const { open, setOpen, severity, message } = useSnackbar();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
